import { enableProdMode, importProvidersFrom, LOCALE_ID } from "@angular/core";

import { environment } from "./environments/environment";
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withDebugTracing,
  withPreloading,
} from "@angular/router";
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from "@ionic/angular/standalone";
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { APP_ROUTES } from "./app/app.routes";
import { bootstrapApplication } from "@angular/platform-browser";
import { IonicStorageModule } from "@ionic/storage-angular";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AppComponent } from "./app/app.component";
import "@angular/common/locales/global/ru";
import { CurrencyPipe, DatePipe, registerLocaleData } from "@angular/common";
import { SpellCountPipe } from "./app/pipes/spell-count/spell-count.pipe";
registerLocaleData("ru");

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    [{ provide: LOCALE_ID, useValue: "ru-RU" }],
    importProvidersFrom(
      IonicStorageModule.forRoot(),
      ServiceWorkerModule.register("ngsw-worker.js", {
        enabled: environment.production,
        registrationStrategy: "registerWhenStable:30000",
      }),
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideHttpClient(withInterceptorsFromDi()),
    provideIonicAngular({
      backButtonText: "",
    }),
    provideRouter(
      APP_ROUTES,
      //withPreloading(PreloadAllModules),
      //withDebugTracing(),
    ),
    DatePipe,
    CurrencyPipe,
    SpellCountPipe,
  ],
}).catch((err) => console.log(err));
