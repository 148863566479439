import { Injectable } from "@angular/core";
import { LoadingController, Platform, ToastController } from "@ionic/angular/standalone";
import { KeyValue } from "@angular/common";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError, Observable, shareReplay, take } from "rxjs";

import { EventsService } from "./events.service";
import { StorageService } from "./storage.service";
import { UserService } from "./user.service";

import { BehaviorSubject, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  appVer: string;
  isUser: BehaviorSubject<boolean>;
  token: string;
  private loading: any;
  private pvzs$: Observable<any>;
  private users$: Observable<any>;
  private roles$: Observable<any>;
  private pvzsGroups$: Observable<any>;
  private formulas$: Array<Observable<any>>; // при добавлении еще, добавлять очищение в clearObservableCache()
  pvzTypes: { [key: number]: { full: string; short: string } }; // список специализаций
  syncUserDataReq: any;

  constructor(
    private events: EventsService,
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    private platform: Platform,
    private storage: StorageService,
    private toastCtrl: ToastController,
    private user: UserService,
  ) {
    this.appVer = "9.1.0.1"; // TODO поднять при выкатке на прод и не забыть в package.json и отправить пуши в систему о новой версии
    this.isUser = new BehaviorSubject<boolean>(null);
    this.getToken();

    this.pvzTypes = {
      // битовая маска типов ПВЗ
      1: { full: "Wildberries", short: "WB" }, // Wildberries
      2: { full: "Ozon", short: "OZ" }, // Ozon
      4: { full: "Яндекс Маркет", short: "ЯМ" }, // Яндекс Маркет
      8: { full: "CDEK", short: "CD" }, // CDEK
      16: { full: "Boxberry", short: "BB" }, // Boxberry
      32: { full: "Авито+Exmail", short: "AV" }, // Avito
      64: { full: "DP Express", short: "DP" },
      128: { full: "DPD", short: "DPD" },
      256: { full: "AliExpress", short: "ALI" },
      512: { full: "Grastin", short: "GR" },
      1024: { full: "ПЭК", short: "ПЭК" },
      2048: { full: "СберЛогистика", short: "СЛ" },
      4096: { full: "Emex", short: "EM" },
      8192: { full: "SimaLand", short: "SL" },
    };
    // TODO добавить также в шаблон импорта и в обработку импорта юзеров и пвз
  }

  getUrlQuery(): string {
    let platform: string;
    if (this.platform.is("ios")) platform = "ios";
    else if (this.platform.is("android")) platform = "android";
    else if (this.platform.is("pwa")) platform = "pwa";
    else if (this.platform.is("mobile")) platform = "mobile";
    else if (this.platform.is("desktop")) platform = "web";
    else if (this.platform.is("mobileweb")) platform = "mweb";
    let query = `v=${this.appVer}&platform=${platform}&tz_offset=${new Date().getTimezoneOffset() / 60}&uuid=${this.user.data.uuid}`;
    if (this.user.data.id) query += `&id=${this.user.data.id}`;
    return query;
  }

  /**
   * Обработчик ошибок при выполнении запросов к API
   * @param error Ошибка при выполнении запроса
   * @returns Генерирует ошибку
   */
  handleError(error: HttpErrorResponse) {
    let errorMessage = "Неизвестная ошибка!";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Ошибка: ${error.error.message}`;
    } else if (error.status == 401) {
      errorMessage = error.error.message;
      this.setToken(null);
    } else if (error.status == 0) {
      errorMessage = "Ошибка при выполнении запроса. Проверьте подключение к Интернету и повторите попытку.";
    } else if (error.error && error.error.message) {
      errorMessage = `Ошибка при выполнении запроса: ${error.error.message}`;
    } else {
      errorMessage = `Ошибка при выполнении запроса.`;
    }

    return of({ success: false, code: error.status, message: errorMessage });
  }

  get(endpoint: string, params?: any, options?: any): Observable<any> {
    if (!options)
      options = {
        headers: this.token ? new HttpHeaders({ "x-token": this.token }) : new HttpHeaders(),
      };

    let query = new URLSearchParams();
    if (params) for (let key in params) query.set(key, params[key]);

    return this.http
      .get(
        environment.apiUrl + "/" + endpoint + "?" + (params ? query.toString() + "&" : "") + this.getUrlQuery(),
        options,
      )
      .pipe(
        catchError((error) => {
          return this.handleError(error);
        }),
      );
  }

  post(endpoint: string, body: any, json: boolean = false): Observable<any> {
    let headers = json ? new HttpHeaders({ "Content-Type": "application/json" }) : new HttpHeaders();
    if (this.token != null) headers = headers.append("x-token", this.token);
    let options = { headers };

    return this.http.post(environment.apiUrl + "/" + endpoint + "?" + this.getUrlQuery(), body, options).pipe(
      catchError((error) => {
        return this.handleError(error);
      }),
    );
  }

  syncUserData(
    key: "globalSettings" | "appSettings" | "pvzsSettings" | "shiftsSettings" | "statsSettings" | "usersSettings",
    showError?: boolean,
  ) {
    if (this.user.data.id) {
      this.user.saveUserData();

      if (this.syncUserDataReq) this.syncUserDataReq.unsubscribe();
      this.syncUserDataReq = this.post("user_settings", this.user.getSyncData(key), true)
        .pipe(take(1))
        .subscribe({
          next: (res: any) => {
            if (!res.success && showError) {
              this.toastPresent(
                "Не удалось сохранить настройки. Проверьте подключение к интернету и повторите попытку.",
              );
            }
          },
        });
    }
  }

  clearObservableCache() {
    this.pvzs$ = null;
    this.users$ = null;
    this.formulas$ = null;
    this.roles$ = null;
    this.pvzsGroups$ = null;
  }

  /**
   * Получает список ПВЗ и расшаривает результат с другими подписчиками
   * @param force принудительно запрашивает список ПВЗ с сервера после добавления нового ПВЗ
   * @returns поток для получения списка
   */
  getPvzs(force?: boolean): Observable<any> {
    if (!this.pvzs$ || force) this.pvzs$ = this.get(`v1/pvzs`).pipe(shareReplay({ bufferSize: 1, refCount: true }));

    return this.pvzs$;
  }

  /**
   * Получает список групп ПВЗ и расшаривает результат с другими подписчиками, кеширует, если надо получить все группы
   * @returns поток для получения списка
   */
  getPvzsGroups(force?: boolean): Observable<any> {
    if (!this.pvzsGroups$ || force)
      this.pvzsGroups$ = this.get(`v1/pvzs_groups`, { only_title: 1 }).pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    return this.pvzsGroups$;
  }

  /**
   * Получает список названий должностей и расшаривает результат с другими подписчиками, кеширует, если надо получить все должности
   * @returns поток для получения списка
   */
  getRoles(force?: boolean): Observable<any> {
    if (!this.roles$ || force)
      this.roles$ = this.get(`v1/roles`, { only_names: 1 }).pipe(shareReplay({ bufferSize: 1, refCount: true }));

    return this.roles$;
  }

  /**
   * Получает список формул для расчета и расшаривает результат с другими подписчиками, кеширует, если надо получить все должности
   * @returns поток для получения списка
   */
  getFormulas(type: number, force?: boolean): Observable<any> {
    if (!this.formulas$) this.formulas$ = [];
    if (!this.formulas$[type] || force)
      this.formulas$[type] = this.get(`v1/salary_formulas`, { type }).pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    return this.formulas$[type];
  }

  /**
   * Получает список сотрудников и расшаривает результат с другими подписчиками, кеширует, если надо получить всех сотрудников
   * @param pvz_id id пвз, по которому ограничить список сотрудников
   * @returns поток для получения списка
   */
  getUsers(pvz_id?: number, force?: boolean): Observable<any> {
    if (pvz_id) {
      return this.get(`v1/users`, { pvz_id });
    } else {
      if (!this.users$ || force)
        this.users$ = this.get(`v1/users`).pipe(shareReplay({ bufferSize: 1, refCount: true }));

      return this.users$;
    }
  }

  async getToken() {
    let token = await this.storage.get("token");

    if (token) {
      this.token = token;
      this.isUser.next(true);
    } else {
      this.isUser.next(false);
    }
  }

  setToken(token: string) {
    this.token = token;
    if (token == null) {
      this.isUser.next(false);
      this.events.publishUserNoauth();
    } else {
      this.isUser.next(true);
    }
    this.storage.set("token", this.token);
  }

  async loadingPresent() {
    if (typeof this.loading == "undefined") {
      this.loading = new LoadingController(); // костыль, чтобы dismiss корректно отрабатывал при нескольких параллельных загрузках
      this.loading = await this.loadingCtrl.create({
        message: "Загружаем...",
        id: "loading",
      });
      return await this.loading.present();
    }
  }

  loadingDismiss() {
    if (typeof this.loading != "undefined" && this.loading.id) {
      this.loading.dismiss();
      this.loading = undefined;
    }
  }

  async toastPresent(text: string, position?: "top" | "middle" | "bottom") {
    let toast = await this.toastCtrl.create({
      message: text,
      duration: 3000,
      position: position ? position : "top",
      swipeGesture: "vertical",
    });
    return await toast.present();
  }

  /**
   * Проверка почтового адреса на валидность
   * @param { string } email почтовый адрес для проверки
   * @returns { boolean } валидный адрес или нет
   */
  emailValid(email: string): boolean {
    return /^.+@.+\..+$/i.test(email);
  }

  /**
   *
   * @param source строка с объектом правил для расчета награды
   * @param hours количество часов на смене, чтобы правильно посчитать общую сумму
   * @returns массив объектов с правилами для
   */
  buildShiftFormula(source: string, hours: number) {
    let formula: any,
      rules: any = [];
    try {
      formula = JSON.parse(source);
      for (let i = 0; i < formula.length; i++) {
        let key: string = Object.keys(formula[i])[0],
          name: string,
          type: number /* 1 - в рублях, 2 - проценты */;
        let value: any = formula[i][key];
        switch (key) {
          case "fixed":
            (name = "Ставка за смену"), (type = 1);
            break;
          case "fixed_1":
            (name = "Ставка за смену (1 чел.)"), (type = 1);
            break;
          case "fixed_2":
            (name = "Ставка за смену (2 чел.)"), (type = 1);
            break;
          case "fixed_3":
            (name = "Ставка за смену (3 чел.)"), (type = 1);
            break;
          case "fixed_4":
            (name = "Ставка за смену (4 чел.)"), (type = 1);
            break;
          case "fixed_hour":
            (name = "Ставка за часы"), (value = formula[i][key] * hours);
            type = 1;
            break;
          case "fixed_hour_1":
            (name = "Ставка за часы (1 чел.)"), (value = formula[i][key] * hours);
            type = 1;
            break;
          case "fixed_hour_2":
            (name = "Ставка за часы (2 чел.)"), (value = formula[i][key] * hours);
            type = 1;
            break;
          case "fixed_hour_3":
            (name = "Ставка за часы (3 чел.)"), (value = formula[i][key] * hours);
            type = 1;
            break;
          case "fixed_hour_4":
            (name = "Ставка за часы (4 чел.)"), (value = formula[i][key] * hours);
            type = 1;
            break;
          case "ready_count_min":
            name = "Кол-во принятых без оплаты";
            break;
          case "ready_count":
            name = "Премия за принятый товар";
            type = 1;
            break;
          case "ready_price":
            name = "Процент от стоимости принятого товара";
            type = 1;
            break;
          case "recieved_count_min":
            name = "Кол-во выданных без оплаты";
            break;
          case "recieved_count":
            name = "Премия за выданный товар";
            type = 1;
            break;
          case "recieved_price":
            name = "Процент от стоимости выданного товара";
            type = 1;
            break;
          case "returned_count_min":
            name = "Кол-во возвратов без оплаты";
            break;
          case "returned_count":
            name = "Премия за возвращённый товар";
            type = 1;
            break;
          case "returned_price":
            name = "Процент от стоимости возвращённого товара";
            type = 1;
            break;
          case "ready_reward":
            name = "Процент от доплаты за приёмку";
            type = 2;
            break;
          case "ready_hold":
            name = "Процент от удержания за приёмку";
            type = 2;
            break;
          case "rating_reward":
            name = "Процент от доплаты за рейтинг";
            type = 2;
            break;
          case "rating_hold":
            name = "Процент от удержания за рейтинг";
            type = 2;
            break;
          case "repack_reward":
            name = "Процент от доплаты за переупаковку";
            type = 2;
            break;
          case "clean":
            name = "Премия за уборку";
            type = 1;
            break;
          case "users":
            name = "Премия за обслуженного клиента";
            type = 1;
            break;
          case "shift_salary_min":
            name = "Минимальная оплата за смену";
            type = 1;
            break;
          case "employment_reward":
            name = "Премия за 1 год стажа";
            type = 1;
            break;
          case "shift_reward_fix":
            name = "Доплата за смену";
            type = 1;
            break;
          // формула зп
          case "salary_type":
            value = null;
            break;
          case "salary_ndfl":
            name = "Ставка НДФЛ";
            value = formula[i][key] + " %";
            break;
          case "salary_ndfl_value":
            name = "Сумма для вычета НДФЛ";
            if (formula[i][key] == -1) value = "от всей зарплаты";
            else type = 1;
            break;
          case "salary_round":
            name = "Округление зарплаты";
            value = "до " + formula[i][key] + " ₽";
            break;
          case "salary_advance_value":
            name = "Сумма аванса";
            value = null;
            break;
          case "salary_overhours":
            name = "Доплата за переработку";
            type = 1;
            break;
          case "salary_reward_fix":
            name = "Доплата к зарплате";
            type = 1;
            break;
          case "salary_reward_proc":
            name = "Доплата к зарплате";
            type = 2;
            break;
          case "salary_hold_fix":
            name = "Удержание от зарплаты";
            type = 1;
            break;
          case "salary_hold_proc":
            name = "Удержание от зарплаты";
            type = 2;
            break;
          case "salary_minimum":
            name = "Минимальная оплата за смену";
            type = 1;
            break;
        }
        if (value) {
          if (type == 1) value = Math.round((value + Number.EPSILON) * 100) / 100;
          rules.push({ name, value, type });
        }
      }

      return rules;
    } catch (e) {
      return false;
    }
  }

  /**
   * Преобразовывает числовой тип смены в текстовый
   * @param status числовой тип смены
   * @param format в каком формате вывести - кратком или полном
   * @returns
   */
  formatShiftStatusName(status: number, format: "short" | "full"): string {
    switch (status) {
      case 1:
        return format == "full" ? "Основная смена" : "Основная";
      case 2:
        return format == "full" ? "Дополнительная смена" : "Дополнит.";
      case 3:
        return format == "full" ? "Отпуск" : "Отпуск";
      case 4:
        return format == "full" ? "Выходной" : "Выходной";
      case 5:
        return format == "full" ? "Больничный" : "Больничный";
    }
  }

  /**
   * Преобразовывает числовое значения типа ПВЗ в текст
   * @param pvz_types типы ПВЗ
   * @param format 'full' | 'short' - в каком формате вернуть
   * @returns { string } массив с текстовыми названиями типов ПВЗ
   */
  formatPvzType(pvz_types: any, format: string): string {
    let types: Array<string> = [];
    for (const type in this.pvzTypes) {
      if (Object.prototype.hasOwnProperty.call(this.pvzTypes, type)) {
        if (pvz_types & parseInt(type)) types.push(this.pvzTypes[type][format]);
      }
    }
    return types.join(format == "full" ? ", " : " + ");
  }

  orderPvzTypes = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    return parseInt(a.key) > parseInt(b.key) ? 1 : -1;
  };

  /**
   * Преобразовывает числовое значение действия пользователя в текст
   * @returns { string } текстовое описание действия пользователя
   */
  formatUserAction(action: number): string {
    switch (action) {
      case 1:
        return "Регистрация в pvzAdmin";
      case 2:
        return "Вход в pvzAdmin через почту";
      case 3:
        return "Вход в pvzAdmin";
      case 4:
        return "Выход из pvzAdmin";
      case 5:
        return "Добавлен ПВЗ";
      case 6:
        return "Изменён ПВЗ";
      case 7:
        return "ПВЗ активирован";
      case 8:
        return "ПВЗ деактивирован";
      case 9:
        return "Удалён ПВЗ";
      case 10:
        return "Добавлен сотрудник";
      case 11:
        return "Изменён сотрудник";
      case 12:
        return "Сотрудник прикреплён к ПВЗ";
      case 13:
        return "Сотрудник откреплён от ПВЗ";
      case 14:
        return "Сотрудник активирован";
      case 15:
        return "Сотрудник переведён в контакты";
      case 16:
        return "Сотрудник уволен";
      case 17:
        return "Добавлена смена";
      case 18:
        return "Изменена смена";
      case 19:
        return "Удалена смена";
      case 20:
        return "Добавлена статистика ПВЗ";
      case 21:
        return "Изменена статистика ПВЗ";
      case 22:
        return "Удалена статистика ПВЗ";
      case 23:
        return "Добавлена финансовая транзакция";
      case 24:
        return "Добавлены повторяющиеся транзакции";
      case 25:
        return "Изменена финансовая транзакция";
      case 26:
        return "Изменены повторяющиеся транзакции";
      case 27:
        return "Удалена финансовая транзакция";
      case 28:
        return "Удалены повторяющиеся транзакции";
      case 29:
        return "Начало смены";
      case 30:
        return "Завершение смены";
      case 31:
        return "Проведена финансовая транзакция";
      case 32:
        return "Добавлена заявка";
      case 33:
        return "Изменена заявка";
      case 34:
        return "Удалена заявка";
      case 35:
        return "Выполнена заявка";
      case 36:
        return "Добавлено начисление";
      case 37:
        return "Изменено начисление";
      case 38:
        return "Удалено начисление";
      case 39:
        return "Добавлена должность";
      case 40:
        return "Изменена должность";
      case 41:
        return "Удалена должность";
      case 42:
        return "Добавлена формула смены";
      case 43:
        return "Изменена формула смены";
      case 44:
        return "Удалена формула смены";
      case 45:
        return "Добавлена формула зарплаты";
      case 46:
        return "Изменена формула зарплаты";
      case 47:
        return "Удалена формула зарплаты";
      case 48:
        return "Начислена выплата";
      case 49:
        return "Изменена выплата";
      case 50:
        return "Удалена выплата";
      case 51:
        return "Импортирована статистика";
      case 52:
        return "Импортированы сотрудники";
      case 53:
        return "Импортированы ПВЗ";
      case 54:
        return "Добавлена группа ПВЗ";
      case 55:
        return "Изменена группа ПВЗ";
      case 56:
        return "Удалена группа ПВЗ";
      case 57:
        return "Сотрудник удалён";
      case 58:
        return "Удалены смены за период";
      case 59:
        return "Одобрен запрос по графику работ";
      case 60:
        return "Отклонён запрос по графику работ";
      case 61:
        return "Добавлен запрос по графику работ";
      case 62:
        return "Импортированы финансовые транзакции";
      case 63:
        return "Добавлено оспаривание";
      case 64:
        return "Изменено оспаривание";
      case 65:
        return "Удалено оспаривание";
      case 66:
        return "Отработано оспаривание";
      case 67:
        return "Вход в pvzAdmin на ПВЗ";
      // не забыть добавить правильный показ данных в alerts.component.html
    }
  }

  /**
   * Преобразовывает дату из одного формата в другой
   * @param { string } date Дата
   * @param { string|string } format В какой формат преобразовать (в DD.MM.YYYY или YYYY-MM-DD)
   * @returns { string } Дата в указанном формате
   */
  formatInputDate(date: string, format: "dd.mm.yyyy" | "yyyy-mm-dd"): string {
    if (!date)
      // если даты нет, то инпут пустой, возвращаем сразу в формате для календаря
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10);

    switch (format) {
      case "dd.mm.yyyy":
        return date.slice(8, 10) + "." + date.slice(5, 7) + "." + date.slice(0, 4);
      case "yyyy-mm-dd":
        return date.slice(6, 10) + "-" + date.slice(3, 5) + "-" + date.slice(0, 2);
    }
  }

  /**
   * Определяет есть ли у сотрудника сегодня знаменательная дата
   * @param user объект с данными сотрудника
   * @returns есть ли сегодня событие или нет
   */
  userHasEventToday(user): boolean {
    return (
      (new Date().getMonth() == new Date(user.birthday).getMonth() &&
        new Date().getDate() == new Date(user.birthday).getDate()) ||
      (new Date().getMonth() == new Date(user.employment_date).getMonth() &&
        new Date().getDate() == new Date(user.employment_date).getDate())
    );
  }
}
