import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  NavController,
  Platform,
} from "@ionic/angular/standalone";
import { environment } from "src/environments/environment";

import { ApiService } from "../../services/api.service";
import { UserService } from "../../services/user.service";
import { YmService } from "../../services/ym.service";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { addIcons } from "ionicons";
import { logInOutline } from "ionicons/icons";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonNote,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonLabel,
  IonIcon,
} from "@ionic/angular/standalone";

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    NgFor,
    NgClass,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonNote,
    IonList,
    IonItem,
    IonInput,
    IonButton,
    IonLabel,
    IonIcon,
  ],
})
export class LoginPage implements OnInit {
  @ViewChild("form_input") formInput: any;
  account: {
    pvz: string; // адрес пвз, если входим по ссылке ПВЗ
    id: number;
    login: string;
    code: string;
    attempts: number;
  };
  formError: string;
  appName: string = environment.appTitle;
  step: number; // текущий шаг авторизации: 1 - ввод почты, 2 - подтверждение кодом, 3 - выбор пользователя
  accounts: Array<any>;
  isNewUser: boolean; // для нового юзера при входе показываем подключение тарифа
  pvzUUID: string; // уникальный код ПВЗ, если входим по ссылке

  constructor(
    private alertCtrl: AlertController,
    public api: ApiService,
    private aRouter: ActivatedRoute,
    private appTitle: Title,
    private navCtrl: NavController,
    private platform: Platform,
    private router: Router,
    public user: UserService,
    private ym: YmService,
  ) {
    this.pvzUUID = this.aRouter.snapshot.paramMap.get("uuid");
    // вход на ПВЗ доступен только с компьютера
    if (this.pvzUUID && !this.platform.is("desktop")) {
      this.api.toastPresent(
        "Вход по ссылке ПВЗ доступен только с компьютера на пункте выдачи.",
      );
      this.router.navigate(["/login"], { replaceUrl: true });
    }
    addIcons({ logInOutline });
  }

  ngOnInit() {
    this.account = {
      pvz: "ПВЗ",
      id: null,
      login: "",
      code: "",
      attempts: null,
    };

    if (this.pvzUUID) {
      // если входим по ссылке ПВЗ
      this.step = 4;
      this.getPvzEmployees();
    } else {
      this.step = 1;
      if (this.user.data.email) this.account.login = this.user.data.email;
    }
  }

  ionViewDidEnter() {
    let title = "Вход в Личный кабинет";
    this.appTitle.setTitle(`${title} | ${environment.appTitle}`);
    this.ym.hit();
    if (this.formInput) setTimeout(() => this.formInput.setFocus(), 200);
  }

  async getPvzEmployees() {
    await this.api.loadingPresent();
    this.api.get(`v1/login/pvz/${this.pvzUUID}`).subscribe({
      next: async (res: any) => {
        this.api.loadingDismiss();

        if (res.success) {
          this.accounts = res.accounts;
          if (res.accounts[0]) this.account.pvz = res.accounts[0].pvz_address;
        } else {
          this.api.toastPresent(res.message);
        }
      },
    });
  }

  sendCode() {
    this.account.login = this.account.login.trim().toLowerCase();
    if (this.api.emailValid(this.account.login)) {
      this.formError = null;

      let doLogin = async (force: boolean) => {
        await this.api.loadingPresent();
        this.api
          .post("v1/login", { login: this.account.login, force }, true)
          .subscribe({
            next: async (res: any) => {
              this.api.loadingDismiss();

              if (res.success) {
                if (res.new) {
                  const alert = await this.alertCtrl.create({
                    message: "В качестве кого вы хотите зарегистрироваться?",
                    buttons: [
                      {
                        text: "Менеджер",
                        handler: async () => {
                          this.ym.reachGoal("REGISTER_USER");
                          const alertt = await this.alertCtrl.create({
                            header: "ВНИМАНИЕ!",
                            message:
                              "Пожалуйста, дождитесь приглашения от вашего руководителя или сообщите ему адрес вашей электронной почты, чтобы он предоставил вам доступ в систему!",
                            buttons: [{ text: "Я понял(а)" }],
                          });

                          await alertt.present();
                        },
                      },
                      {
                        text: "Собственник",
                        handler: async () => {
                          const alertt = await this.alertCtrl.create({
                            header: "ВНИМАНИЕ!",
                            message:
                              "Вы действительно хотите зарегистрироваться в качестве собственника пункта выдачи? Если вы менеджер или другой сотрудник организации, дождитесь от руководителя приглашения в систему!",
                            buttons: [
                              {
                                text: "Отмена",
                                role: "cancel",
                              },
                              {
                                text: "Зарегистрироваться",
                                role: "confirm",
                                handler: () => {
                                  this.ym.reachGoal("REGISTER_OWNER");
                                  this.isNewUser = true;
                                  doLogin(true);
                                },
                              },
                            ],
                          });

                          await alertt.present();
                        },
                      },
                    ],
                  });

                  await alert.present();
                } else {
                  if (force) this.ym.reachGoal("REGISTER_REGISTERED");
                  this.user.data.email = this.account.login;
                  this.user.saveUserData();

                  this.step = 2;
                  setTimeout(() => this.formInput.setFocus(), 200);
                }
              } else {
                this.api.toastPresent(res.message);
              }
            },
          });
      };

      doLogin(false);
    } else {
      this.formError =
        "Проверьте корректность указанного адреса электронной почты.";
    }
  }

  autologin(type: "user" | "pvz") {
    if (this.account.code) {
      if (/\d{4}/.test(this.account.code)) {
        if (type == "user") this.confirmCode();
        else if (
          type == "pvz" &&
          (!this.account.login || this.account.login.length > 0)
        )
          this.confirmPincode();
      }
    }
  }

  async confirmCode() {
    let code = this.account.code ? this.account.code.toString().trim() : "";
    if (/\d{4}/.test(code)) {
      await this.api.loadingPresent();
      this.api
        .post("v1/login", { login: this.account.login, code }, true)
        .subscribe({
          next: (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.account.attempts = null;

              if (res.accounts) {
                this.accounts = res.accounts;
                this.step = 3;
              } else {
                this.doLogin(res);
              }
            } else {
              this.ym.reachGoal("LOGIN_CODE_WRONG");
              this.api.toastPresent(res.message);
              this.account.code = "";
              this.account.attempts = res.login_attempts;
              setTimeout(() => this.formInput.setFocus(), 200);
            }
          },
        });
    } else {
      this.api.toastPresent("Код должен состоять из 4 цифр.");
    }
  }

  confirmEmailTelPincode() {
    let code: string = this.account.code
      ? this.account.code.toString().trim()
      : "";
    let login: string;
    if (/\d{4}/.test(code)) {
      if (this.api.emailValid(this.account.login)) {
        login = this.account.login.trim().toLowerCase();
      } else {
        let phone: string = this.account.login.replace(/[^\d]/g, "");
        if (phone.length >= 11 && phone.length <= 12) {
          this.account.login = phone[0] == "8" ? "7" + phone.slice(1) : phone;
        } else {
          return this.api.toastPresent(
            "Для входа в Личный кабинет укажите ваш адрес электронной почты или номер телефона.",
          );
        }
      }

      this.confirmPincode();
    } else {
      this.api.toastPresent("Пинкод должен состоять из 4 цифр.");
    }
  }

  selectPVZAccount(id: number) {
    if (id > 0) {
      this.account.id = id;
      this.step = 5;
      setTimeout(() => this.formInput.setFocus(), 200);
    } else {
      this.step = 6;
      setTimeout(() => this.formInput.setFocus(), 200);
    }
  }

  async confirmPincode() {
    let code = this.account.code ? this.account.code.toString().trim() : "";
    if (/\d{4}/.test(code)) {
      await this.api.loadingPresent();
      this.api
        .post(
          "v1/login/pvz",
          {
            id: this.account.id,
            login: this.account.login,
            code,
            pvz_uuid: this.pvzUUID,
          },
          true,
        )
        .subscribe({
          next: (res: any) => {
            this.api.loadingDismiss();
            if (res.success) {
              this.account.attempts = null;
              this.doLogin(res);
            } else {
              this.ym.reachGoal("LOGIN_CODE_WRONG");
              this.api.toastPresent(res.message);
              this.account.code = "";
              this.account.attempts = res.login_attempts;
              setTimeout(() => this.formInput.setFocus(), 200);
            }
          },
        });
    } else {
      this.api.toastPresent("Код должен состоять из 4 цифр.");
    }
  }

  async resendCode() {
    await this.api.loadingPresent();
    this.account.attempts = null;
    this.account.code = "";
    this.api.post("v1/login", { login: this.account.login }, true).subscribe({
      next: (res: any) => {
        this.api.loadingDismiss();
        if (res.success) {
          this.api.toastPresent(
            "Код для входа в Личный кабинет выслан повторно.",
          );
        } else {
          this.api.toastPresent(res.message);
        }
      },
    });
  }

  selectAccount(id: number) {
    this.api
      .post("v1/login", { login: this.account.login, owner: id }, true)
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.doLogin(res);
          } else {
            this.api.toastPresent(res.message);
          }
        },
      });
  }

  async doLogin(res: any) {
    await this.api.loadingPresent();
    this.ym.reachGoal("LOGIN");
    this.user.data.id = res.id;
    this.user.data.name = res.name;
    this.user.data.role = res.role;
    this.user.data.roleName = res.role_name;
    this.user.data.subType = res.sub_type;
    this.user.data.subActive = res.sub_active;
    this.user.data.showNPSButton = res.show_nps;
    if (this.pvzUUID) this.user.data.pvzUUID = this.pvzUUID;

    if (this.user.data.role == 0 || this.user.checkPermissions([30])) {
      this.user.data.subUntil = res.sub_until;
      this.user.data.subPrice = res.sub_price;
      this.user.data.subLicenses = res.sub_licenses;
      this.user.subPlans = res.sub_plans;
    }

    this.user.data.permissions = res.permissions;

    if (res.settings) {
      this.user.applySettings(res.settings);
    }

    this.user.saveUserData();

    this.api.setToken(res.token);

    this.ym.userParams();

    this.navCtrl.setDirection("root");
    this.router.navigate(["/app"], {
      state: {
        noCheck: true,
        isNewUser: this.isNewUser,
        needUpdate: res.update,
      },
      replaceUrl: true,
    });
    this.api.loadingDismiss();
  }
}
