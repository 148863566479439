/* src/app/pages/login/login.page.scss */
@media (min-width: 720px) {
  .content-card {
    width: 400px;
  }
}
ion-grid {
  height: 100%;
  padding: 0;
}
ion-grid ion-col {
  padding: 0;
}
#logo {
  height: 15%;
  font-size: 50px;
  font-weight: 300;
}
#logo a {
  text-decoration: none;
}
#logo img {
  width: 36px;
  height: 36px;
  margin-right: 16px;
}
.content-card {
  justify-self: center;
  margin-bottom: 0;
  padding: 32px;
}
#form {
  min-height: 70%;
}
form ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}
ion-item {
  border-radius: 16px;
}
.email {
  text-decoration: underline;
}
.activate-email {
  text-align: center;
  color: var(--ion-color-danger);
  font-size: 14px;
  cursor: pointer;
}
.small {
  font-size: 12px;
}
#version {
  height: 15%;
  font-size: 12px;
  color: var(--ion-color-note);
}
.enter-icon {
  color: var(--ion-color-note);
}
ion-item:hover .enter-icon {
  color: var(--ion-color-light);
}
ion-item ion-label p.shift-today {
  color: var(--ion-color-success);
}
/*# sourceMappingURL=login.page.css.map */
